/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/dayjs@1.11.6/dayjs.min.js
 * - /npm/dayjs@1.11.6/locale/uk.min.js
 * - /npm/dayjs@1.11.6/plugin/relativeTime.min.js
 * - /npm/dayjs@1.11.6/plugin/localizedFormat.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
